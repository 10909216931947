<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('warehouse_config.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="formData.org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Accession Number" vid="accession_number" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="accession_number"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('germplasm.access_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="org_name"
                                v-model="formData.accession_number"
                                :state="errors[0] ? false : (valid ? true : null)"
                                @change="getCollectionData()"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="GID NO." vid="gid_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="gid_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.gid_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="gid_no"
                                  v-model="formData.gid_no"
                                  @change="getCollectionData()"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Crop Name"  vid="crop_name_id">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="crop_name_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.crop')}}
                              </template>
                              <b-form-input
                                readonly
                                v-model="formData.crop_name"
                                id="crop_name_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Scientific Name" vid="scientific">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="scientific"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.scientific')}}
                              </template>
                              <b-form-input
                                readonly
                                id="scientific"
                                v-model="formData.scientific_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="English Name" vid="english">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="english"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.english')}}
                              </template>
                              <b-form-input
                                id="english"
                                readonly
                                v-model="formData.english_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <br><br>
                        <b-col v-for="(item, index) in formData.characterization_info" :key="index" class="col-12">
                            <template>
                              <div class="accordion" role="tablist">
                                  <b-card no-body class="mb-1">
                                  <b-card-header header-tag="header" class="p-1" role="tab">
                                      <b-button block v-b-toggle="'accordion-'+index+1" variant="info" class="text-left capitalize" style="background-color: rgb(34 139 34);">{{ $n(item.descriptor_label_sorting_order) + '.  ' }}{{ $i18n.locale === 'en' ? item.descriptor_label_name : item.descriptor_label_name_bn }}</b-button>
                                  </b-card-header>
                                  <b-collapse :id="'accordion-'+index+1" visible accordion="my-accordion" role="tabpanel">
                                      <b-card-body class="text-black">
                                          <b-row v-for="(txt, index1) in item.descriptor_label_info" :key="index1">
                                              <b-col class="col-md-2">{{ $n(txt.descriptor_heading_sorting_order) }}</b-col>
                                              <b-col class="col-md-3 capitalize">{{ $i18n.locale === 'en' ? txt.descriptor_heading_name : txt.descriptor_heading_name_bn }}</b-col>
                                              <b-col class="col-md-4">
                                                  <div
                                                      class="row"
                                                      label-for="collection_source"
                                                  >
                                                  <template v-if="blankObj[txt.descriptor_heading_id] !== 'undefined' && blankObj[txt.descriptor_heading_id].length">
                                                  <div class="col-md-2 pr-0">{{ $n(getBlankOrder(txt.descriptor_heading_info)) }}</div>
                                                  <div class="col-md-10 pl-0 mb-2">
                                                          <b-form-input
                                                              id="org_name"
                                                              v-model="txt.code_guide_blank_value"
                                                              ></b-form-input>
                                                              </div>
                                                  </template>
                                                      <b-form-checkbox-group
                                                          class="mb-2"
                                                          :id="'code_detail-'+index+ '-' +index1"
                                                          v-model="txt.code_guide_mcq_value"
                                                          :options="$i18n.locale === 'en' ? optionObj[txt.descriptor_heading_id] : optionObjBn[txt.descriptor_heading_id]"
                                                          name="flavour-1"
                                                          stacked
                                                          v-if="optionObj[txt.descriptor_heading_id] !== 'undefined' && optionObj[txt.descriptor_heading_id].length"
                                                      ></b-form-checkbox-group>
                                                  </div>
                                              </b-col>
                                              <b-col class="col-md-3">{{ $i18n.locale === 'en' ? txt.descriptor_heading_description : txt.descriptor_heading_description_bn }}</b-col>
                                          </b-row>
                                      </b-card-body>
                                  </b-collapse>
                                  </b-card>
                              </div>
                              </template>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$router.go(-1)">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { characStore, characUpdate, characShow, characByCollection } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.formData.org_id = this.$store.state.dataFilters.orgId
    if (this.$route.query.id) {
        this.getFormData()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        crop_name_id: 0,
        accession_number: '',
        gid_no: '',
        characterization_info: [],
        org_id: 0
      },
      // CropNameList: [],
      CropNameListCustom: [],
      CropTypeListCustom: [],
      inputList: [],
      optionObj: {},
      optionObjBn: {},
      blankObj: {},
      characterizationDetails: []
      // loading: false
    }
  },
  computed: {
    CropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
    },
    descriptorLabelList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.descriptorLabelList.filter(item => item.status === 1)
    },
    descriptorHeadingList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.descriptorHeadingList
    },
    codeGuideDetail: function () {
      return this.$store.state.SeedsFertilizer.commonObj.codeGuideDetail
    },
    characterizationTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.characterizationTypeList.filter(item => item.status === 1)
    },
    CropNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    loading () {
      return this.$store.state.commonObj.loading
    }
  },
  watch: {
    // 'formData.crop_type_id': function (newVal, oldVal) {
    //   this.CropNameList = this.getCropNameList(newVal)
    // }
  },
  methods: {
      getBlankOrder (arr) {
          const codeGuide = arr.find(item => item.code_guide_type === 2)
          return typeof codeGuide !== 'undefined' ? codeGuide.code_guide_sorting_order : 2
      },
    async getFormData () {
        const result = await RestApi.getData(seedFertilizerServiceBaseUrl, characShow + this.$route.query.id)
        this.formData.id = result.data.id
        this.formData.org_id = result.data.org_id
        this.formData.accession_number = result.data.accession_number
        this.formData.gid_no = result.data.gid_no
        this.formData.crop_name_id = result.data.crop_name_id
        this.characterizationDetails = result.data.characterization_details
      this.getCollectionData()
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${characUpdate}/${this.$route.query.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, characStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$router.push('/seeds-fertilizer-service/germplasm-germplasm/characterization')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getCropNameList (cropTypeId = null) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      if (cropTypeId) {
        return cropNameList.filter(union => union.cropTypeId === cropTypeId)
      }
      return cropNameList
    },
    getCollectionData () {
      if (!(this.formData.accession_number && this.formData.gid_no)) {
        return false
      }
      // this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      this.formData.characterization_info = []
      const params = Object.assign({}, {
        accession_number: this.formData.accession_number,
        gid_no: this.formData.gid_no
      })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(seedFertilizerServiceBaseUrl, characByCollection, params).then(response => {
          if (response.success) {
              this.getCustomDataList(response.data)
                response.data.descriptor_labels.forEach(item => {
                    const levelObj = {
                        descriptor_label_id: item.id,
                        descriptor_label_name: item.heading_name,
                        descriptor_label_name_bn: item.heading_name_bn,
                        descriptor_label_sorting_order: item.sorting_order,
                        descriptor_label_info: []
                    }
                    item.descriptor_heading_details.forEach(list => {
                        const characterDetail = this.characterizationDetails.find(doc => doc.descriptor_label_id === item.id && doc.descriptor_heading_id === list.id)
                        const headingObj = {
                            descriptor_heading_id: list.id,
                            descriptor_heading_name: list.heading_name,
                            descriptor_heading_name_bn: list.heading_name_bn,
                            descriptor_heading_sorting_order: list.heading_sl,
                            descriptor_heading_description: list.description,
                            descriptor_heading_description_bn: list.description_bn,
                            descriptor_heading_info: [],
                            code_guide_mcq_value: typeof characterDetail !== 'undefined' ? characterDetail.code_guide_mcq_value : [],
                            code_guide_blank_value: typeof characterDetail !== 'undefined' ? characterDetail.code_guide_blank_value : ''
                        }
                        const newList = []
                        const newListBn = []
                        const newList2 = []
                        list.code_guide_details.forEach(doc => {
                            const codeObj = {
                            code_guide_id: doc.id,
                            code_guide_name: this.$i18n.locale === 'en' ? doc.code_guide_name : doc.code_guide_name_bn,
                            code_guide_sorting_order: doc.code_guide_sl,
                            code_guide_type: doc.type_id
                            }
                            if (doc.type_id === 1) {
                                const obj = {
                                    value: doc.id,
                                    text: this.$n(doc.code_guide_sl) + '. ' + doc.code_guide_name
                                }
                                const objBn = {
                                    value: doc.id,
                                    text: this.$n(doc.code_guide_sl) + '. ' + doc.code_guide_name_bn
                                }
                                newList.push(obj)
                                newListBn.push(objBn)
                            }
                            if (doc.type_id === 2) {
                                const obj = {
                                    value: doc.id,
                                    text: this.$i18n.locale === 'en' ? this.$n(doc.code_guide_sl) + '. ' + doc.code_guide_name : this.$n(doc.code_guide_sl) + doc.code_guide_name_bn
                                }
                                newList2.push(obj)
                            }
                            headingObj.descriptor_heading_info.push(codeObj)
                        })
                       this.optionObj[list.id] = newList
                       this.optionObjBn[list.id] = newListBn
                       this.blankObj[list.id] = newList2
                       levelObj.descriptor_label_info.push(headingObj)
                    })
                    this.formData.characterization_info.push(levelObj)
                })
               this.formData.crop_name_id = response.data.crop_name_id
          } else {
             this.$toast.error({
                title: 'Info',
                message: response.message,
                color: '#D6E09B'
            })
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        // this.loading = false
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      })
    },
    getCustomDataList (data) {
      const cropName = this.CropNameList.find(document => document.value === data.crop_name_id)
      const cropType = this.CropTypeList.find(document => document.value === data.crop_type_id)
      const list = {
        crop_name: this.$i18n.locale === 'bn' ? cropName.text_bn : cropName.text_en,
        crop_type: this.$i18n.locale === 'bn' ? cropType.text_bn : cropName.text_en,
        english_name: this.$i18n.locale === 'bn' ? cropName.english_name_bn : cropName.english_name,
        scientific_name: this.$i18n.locale === 'bn' ? cropName.scientific_name_bn : cropName.scientific_name
      }
      Object.assign(this.formData, list)
      this.CropTypeListCustom = this.CropTypeList.filter(document => document.value === data.crop_type_id)
      this.CropNameListCustom = this.CropNameList.filter(document => document.value === data.crop_type_id)
      this.formData.crop_type_id = data.crop_type_id
      this.formData.crop_name_id = data.crop_name_id
      this.formData.local_name = this.$i18n.locale === 'bn' ? data.local_name_bn : data.local_name
      this.formData.cultivar_name = this.$i18n.locale === 'bn' ? data.cultivar_name_bn : data.cultivar_name
    }
  }
}
</script>
