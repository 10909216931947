// germplasm collection api
export const collectionList = '/germplasm/germplasm/collections/list'
export const collectionStore = '/germplasm/germplasm/collections/store'
export const collectionShow = '/germplasm/germplasm/collections/show/'
export const collectionUpdate = '/germplasm/germplasm/collections/update'
export const collectionToggleStatus = '/germplasm/germplasm/collections/toggle-status'
export const collectionDestroy = '/germplasm/germplasm/collections/destroy'

// passport data list
export const passportDataList = '/germplasm/germplasm/collections/list'
export const passportDataImport = '/germplasm/germplasm/collections/import'
export const passportDataExport = '/germplasm/germplasm/collections/export'

// characterization form
export const characterizationList = '/germplasm/config/characterization-form/list'
export const characterizationShow = '/germplasm/config/characterization-form/show/'

// characterization
export const characByCollection = '/germplasm/germplasm/characterization/colection-search'
export const characList = '/germplasm/germplasm/characterization/list'
export const characStore = '/germplasm/germplasm/characterization/store'
export const characShow = '/germplasm/germplasm/characterization/show/'
export const characUpdate = '/germplasm/germplasm/characterization/update'
export const characToggleStatus = '/germplasm/germplasm/characterization/toggle-status'
export const characDestroy = '/germplasm/germplasm/characterization/destroy'

export const checkStatusByCollection = '/germplasm/germplasm/check-status/get-details'
export const checkStatusByCollectionNew = '/germplasm/germplasm/check-status/get-details-new'
export const checkStatusList = '/germplasm/germplasm/check-status/list'
export const checkStatusStore = '/germplasm/germplasm/check-status/store'
export const checkStatusUpdate = '/germplasm/germplasm/check-status/update'

// conservation type api
export const getDataByAccessionNumber = '/germplasm/germplasm/conservation-details/get-details'
export const conservationDetailsList = '/germplasm/germplasm/conservation-details/list'
export const conservationDetailsStore = '/germplasm/germplasm/conservation-details/store'
export const conservationDetailsUpdate = '/germplasm/germplasm/conservation-details/update'
export const conservationDetailsToggleStatus = '/germplasm/germplasm/conservation-details/toggle-status'
export const conservationDetailsDestroy = '/germplasm/germplasm/conservation-details/destroy'

// production information
export const getDataByAccessionNumberForProduction = '/germplasm/germplasm/check-status/get-details'
export const getDataByAccessionNumberForProductionNew = '/germplasm/germplasm/check-status/get-details-new'
export const productionInfoList = '/germplasm/germplasm/productions/list'
export const productionInfoStore = '/germplasm/germplasm/productions/store'
export const productionInfoUpdate = '/germplasm/germplasm/productions/update'
export const productionInfoToggleStatus = '/germplasm/germplasm/productions/toggle-status'
export const productionInfoDestroy = '/germplasm/germplasm/productions/destroy'

// germplasm request api
export const getDataByRecieverCode = '/germplasm/config/receiver-info/get-by-reciever-code/'
export const getDataByOrgId = '/germplasm/config/organization/show/'
export const getAccessionInfo = 'germplasm/germplasm/check-status/get-details'

// germplasm request api
// germplasm collection api
export const germplasmRequestList = '/germplasm/germplasm/requests/list'
export const germplasmRequestStore = '/germplasm/germplasm/requests/store'
export const germplasmRequestUpdate = '/germplasm/germplasm/requests/update'
export const germplasmRequestToggleStatus = '/germplasm/germplasm/requests/toggle-status'
export const germplasmRequestDestroy = '/germplasm/germplasm/requests/destroy'
export const germplasmRequestApprove = '/germplasm/germplasm/requests/approve'
export const germplasmRequestReject = '/germplasm/germplasm/requests/reject'
export const germplasmDistribution = '/germplasm/germplasm/distributions/update'

// germplasm characterization info
export const characterizationInfo = '/germplasm/germplasm/characterization-info'
export const reportHeadingList = '/germplasm/config/report-heading/detail'
